

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'DocumentIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.1667 2.16699H6.50004C5.30296 2.16699 4.34421 3.13658 4.34421 4.33366L4.33337 21.667C4.33337 22.8641 5.29212 23.8337 6.48921 23.8337H19.5C20.6971 23.8337 21.6667 22.8641 21.6667 21.667V8.66699L15.1667 2.16699ZM17.3334 19.5003H8.66671V17.3337H17.3334V19.5003ZM17.3334 15.167H8.66671V13.0003H17.3334V15.167ZM14.0834 9.75033V3.79199L20.0417 9.75033H14.0834Z" fill="#01BCB5"/>
  </svg>
  `
});

export default script;