

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'EditIcon',
  props: ['color'],
  template: `
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_106_1939)">
  <path d="M17.8185 4.4245L13.5755 0.181442C13.4593 0.0652728 13.3017 0 13.1373 0C12.973 0 12.8154 0.0652728 12.6992 0.181442L0.314711 12.5659C0.203169 12.6776 0.138309 12.8275 0.133517 12.9852L0.00040984 17.3615C-0.00479546 17.5323 0.0608078 17.6977 0.181604 17.8186C0.298021 17.9349 0.455667 18 0.619758 18C0.626037 18 0.632316 17.9999 0.638679 17.9997L5.01476 17.8664C5.17249 17.8616 5.32245 17.7968 5.43408 17.6853L17.8186 5.30089C18.0605 5.05889 18.0605 4.66651 17.8185 4.4245ZM4.73136 16.6351L1.25918 16.7409L1.36486 13.2685L8.89387 5.73946L12.2608 9.1058L4.73136 16.6351ZM13.1372 8.22949L9.77018 4.86323L13.1373 1.49607L16.5038 4.86274L13.1372 8.22949Z" fill="#12366C"/>
  </g>
  <defs>
  <clipPath id="clip0_106_1939">
  <rect width="18" height="18" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  `
});

export default script;