

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'MinMaxIcon',
  props: ['color'],
  template: `
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.87468 2.39165L7.87467 11.6083C7.87467 12.4833 8.24801 12.8333 9.17551 12.8333L11.5322 12.8333C12.4597 12.8333 12.833 12.4833 12.833 11.6083L12.833 2.39165C12.833 1.51665 12.4597 1.16665 11.5322 1.16665L9.17551 1.16665C8.24801 1.16665 7.87468 1.51665 7.87468 2.39165Z" stroke="#01BCB5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1.16667 6.47498L1.16667 11.6083C1.16667 12.4833 1.54 12.8333 2.4675 12.8333L4.82417 12.8333C5.75167 12.8333 6.125 12.4833 6.125 11.6083L6.125 6.47498C6.125 5.59998 5.75167 5.24998 4.82417 5.24998L2.4675 5.24998C1.54 5.24998 1.16667 5.59998 1.16667 6.47498Z" stroke="#01BCB5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;