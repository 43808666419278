

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'ChannelIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.66656 23.8333H17.3332C21.6882 23.8333 22.4682 22.0892 22.6957 19.9658L23.5082 11.2992C23.8007 8.65583 23.0424 6.5 18.4166 6.5H7.58323C2.95739 6.5 2.19906 8.65583 2.49156 11.2992L3.30406 19.9658C3.53156 22.0892 4.31156 23.8333 8.66656 23.8333Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.66675 6.5V5.63334C8.66675 3.71584 8.66675 2.16667 12.1334 2.16667H13.8667C17.3334 2.16667 17.3334 3.71584 17.3334 5.63334V6.5" :stroke="color || '#22396D'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.1666 14.0833V15.1667C15.1666 15.1775 15.1666 15.1775 15.1666 15.1883C15.1666 16.3692 15.1558 17.3333 12.9999 17.3333C10.8549 17.3333 10.8333 16.38 10.8333 15.1992V14.0833C10.8333 13 10.8333 13 11.9166 13H14.0833C15.1666 13 15.1666 13 15.1666 14.0833Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M23.4542 11.9167C20.9517 13.7367 18.0917 14.82 15.1667 15.1883" :stroke="color || '#22396D'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.83838 12.2092C5.27588 13.8775 8.02755 14.885 10.8334 15.1992" :stroke="color || '#22396D'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;