

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'PromocodeIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.9998 23.8333C18.9829 23.8333 23.8332 18.9831 23.8332 13C23.8332 7.01692 18.9829 2.16667 12.9998 2.16667C7.01675 2.16667 2.1665 7.01692 2.1665 13C2.1665 18.9831 7.01675 23.8333 12.9998 23.8333Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.75 16.25L16.25 9.75" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.7022 15.7083H15.712" :stroke="color || '#22396D'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.2857 10.2917H10.2954" :stroke="color || '#22396D'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>  
  `
});

export default script;