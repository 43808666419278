

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'LinkIcon',
  props: ['color'],
  template: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.0499 10.9501C15.2785 13.1787 15.2785 16.784 13.0499 19.0027C10.8213 21.2213 7.216 21.2312 4.99734 19.0027C2.77867 16.7741 2.76877 13.1688 4.99734 10.9501" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.6034 13.3966C8.28571 11.0789 8.28571 7.31504 10.6034 4.98742C12.9211 2.65981 16.685 2.66971 19.0126 4.98742C21.3402 7.30514 21.3303 11.0689 19.0126 13.3966" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;