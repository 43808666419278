import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'RightArrowIcon',
  props: ['color'],
  template: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9 18L15 12L9 6" :stroke="color || '#3ECD7B'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;