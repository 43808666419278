

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'PlusIcon',
  props: ['color'],
  template: `
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.99998 11.6666V6.99998M6.99998 6.99998V2.33331M6.99998 6.99998H11.6666M6.99998 6.99998H2.33331" :stroke="color || '#01BCB5'" stroke-width="2" stroke-linecap="round"/>
  </svg>
  `
});

export default script;