

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'ProdamusIcon',
  props: ['selected'],
  template: `
  <svg v-if="!selected" width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2.79274 31.8242V39.9999H1V30.506H2.65781L2.79274 31.8242ZM6.40393 33.7002V34.1565C6.40393 34.6931 6.35681 35.1684 6.26257 35.5825C6.16833 35.9924 6.02696 36.3409 5.83848 36.6282C5.65428 36.9113 5.42296 37.1268 5.14451 37.2747C4.86607 37.4183 4.54051 37.4902 4.16782 37.4902C3.82084 37.4902 3.52098 37.4099 3.26824 37.2493C3.01978 37.0846 2.80988 36.8585 2.63853 36.5712C2.46718 36.2839 2.3301 35.948 2.22729 35.5635C2.12448 35.179 2.04523 34.7671 1.98954 34.3276V33.6178C2.04095 33.1488 2.11591 32.7178 2.21444 32.3249C2.31296 31.9277 2.4479 31.5834 2.61925 31.2919C2.79488 31.0003 3.00693 30.7764 3.25539 30.6201C3.50813 30.4595 3.80799 30.3792 4.15497 30.3792C4.53194 30.3792 4.85964 30.4468 5.13809 30.582C5.42081 30.7172 5.65428 30.9222 5.83848 31.1968C6.02696 31.4672 6.16833 31.8115 6.26257 32.2298C6.35681 32.6481 6.40393 33.1383 6.40393 33.7002ZM4.60476 34.1565V33.7002C4.60476 33.3706 4.58549 33.0897 4.54693 32.8573C4.51266 32.6249 4.45697 32.4348 4.37987 32.2869C4.30276 32.139 4.20209 32.0313 4.07786 31.9637C3.95363 31.8961 3.80156 31.8623 3.62165 31.8623C3.44601 31.8623 3.2918 31.8961 3.159 31.9637C3.03049 32.0313 2.92554 32.1305 2.84415 32.2615C2.76276 32.3883 2.70064 32.5446 2.65781 32.7305C2.61497 32.9122 2.59141 33.1192 2.58712 33.3516V34.5938C2.59569 34.8685 2.6321 35.1114 2.69636 35.3227C2.7649 35.5339 2.87413 35.7029 3.02406 35.8297C3.174 35.9522 3.37747 36.0135 3.6345 36.0135C3.8187 36.0135 3.97077 35.9755 4.09071 35.8994C4.21494 35.8233 4.31561 35.7093 4.39272 35.5572C4.46983 35.4051 4.52337 35.2128 4.55336 34.9804C4.58763 34.748 4.60476 34.4734 4.60476 34.1565Z" fill="black"/>
  <path d="M9.3083 32.0334V37.3634H7.50913V30.506H9.20549L9.3083 32.0334ZM10.9468 30.4553L10.9211 32.2235C10.8397 32.2066 10.7412 32.1939 10.6256 32.1855C10.5142 32.177 10.4156 32.1728 10.33 32.1728C10.1286 32.1728 9.95729 32.2045 9.81593 32.2679C9.67456 32.327 9.55676 32.4179 9.46252 32.5404C9.37256 32.6587 9.30402 32.8066 9.2569 32.984C9.20978 33.1615 9.18193 33.3622 9.17337 33.5861L8.83281 33.4594C8.83281 33.0115 8.86708 32.6017 8.93562 32.2298C9.00844 31.8538 9.11553 31.5285 9.2569 31.2538C9.39826 30.975 9.56747 30.7595 9.76452 30.6074C9.96586 30.4553 10.1929 30.3792 10.4456 30.3792C10.5313 30.3792 10.6213 30.3877 10.7155 30.4046C10.814 30.4172 10.8911 30.4341 10.9468 30.4553Z" fill="black"/>
  <path d="M11.4288 34.1692V33.7065C11.4288 33.1699 11.493 32.6946 11.6215 32.2805C11.7543 31.8665 11.9428 31.52 12.187 31.2412C12.4311 30.9581 12.7246 30.7447 13.0673 30.601C13.4143 30.4532 13.8019 30.3792 14.2303 30.3792C14.663 30.3792 15.0506 30.4532 15.3933 30.601C15.7403 30.7447 16.0359 30.9581 16.2801 31.2412C16.5285 31.52 16.7192 31.8665 16.852 32.2805C16.9848 32.6946 17.0512 33.1699 17.0512 33.7065V34.1692C17.0512 34.7016 16.9848 35.1748 16.852 35.5889C16.7192 36.0029 16.5285 36.3515 16.2801 36.6346C16.0359 36.9177 15.7425 37.131 15.3998 37.2747C15.0571 37.4183 14.6715 37.4902 14.2432 37.4902C13.8148 37.4902 13.4271 37.4183 13.0801 37.2747C12.7374 37.131 12.4419 36.9177 12.1934 36.6346C11.9449 36.3515 11.7543 36.0029 11.6215 35.5889C11.493 35.1748 11.4288 34.7016 11.4288 34.1692ZM13.2279 33.7065V34.1692C13.2279 34.4861 13.2493 34.7607 13.2922 34.9931C13.3393 35.2255 13.4057 35.4177 13.4914 35.5698C13.5813 35.7219 13.6884 35.8339 13.8126 35.9057C13.9369 35.9776 14.0804 36.0135 14.2432 36.0135C14.4059 36.0135 14.5516 35.9776 14.6801 35.9057C14.8086 35.8339 14.9136 35.7219 14.995 35.5698C15.0806 35.4177 15.1427 35.2255 15.1813 34.9931C15.2241 34.7607 15.2456 34.4861 15.2456 34.1692V33.7065C15.2456 33.3981 15.222 33.1277 15.1749 32.8953C15.1278 32.6587 15.0614 32.4665 14.9757 32.3186C14.89 32.1665 14.7829 32.0524 14.6544 31.9763C14.5302 31.8961 14.3888 31.8559 14.2303 31.8559C14.0718 31.8559 13.9305 31.8961 13.8062 31.9763C13.6863 32.0524 13.5813 32.1665 13.4914 32.3186C13.4057 32.4665 13.3393 32.6587 13.2922 32.8953C13.2493 33.1277 13.2279 33.3981 13.2279 33.7065Z" fill="black"/>
  <path d="M21.5555 35.8487V27.6286H23.3611V37.3634L21.729 37.3698L21.5555 35.8487ZM17.9507 34.1755V33.7192C17.9507 33.1615 17.9979 32.6735 18.0921 32.2552C18.1906 31.8369 18.3363 31.4883 18.529 31.2095C18.7218 30.9306 18.9574 30.7236 19.2359 30.5884C19.5186 30.4489 19.842 30.3792 20.2061 30.3792C20.536 30.3792 20.8251 30.4616 21.0736 30.6264C21.3263 30.7869 21.5405 31.013 21.7161 31.3045C21.8918 31.5961 22.0331 31.9404 22.1402 32.3376C22.2473 32.7347 22.3223 33.1699 22.3651 33.6432V34.3023C22.3137 34.7502 22.2345 35.1684 22.1274 35.5572C22.0246 35.9459 21.8832 36.2839 21.7033 36.5712C21.5277 36.8585 21.3156 37.0846 21.0672 37.2493C20.8187 37.4099 20.5295 37.4902 20.1997 37.4902C19.8356 37.4902 19.5122 37.4183 19.2294 37.2747C18.951 37.131 18.7154 36.9198 18.5226 36.6409C18.3341 36.3578 18.1906 36.0114 18.0921 35.6015C17.9979 35.1875 17.9507 34.7121 17.9507 34.1755ZM19.7499 33.7192V34.1755C19.7499 34.4966 19.7649 34.7734 19.7949 35.0058C19.8292 35.2339 19.8827 35.422 19.9555 35.5698C20.0326 35.7135 20.1333 35.8212 20.2575 35.8931C20.3818 35.9607 20.536 35.9945 20.7202 35.9945C20.9601 35.9945 21.155 35.9353 21.3049 35.817C21.4548 35.6945 21.5662 35.5276 21.639 35.3163C21.7161 35.1008 21.759 34.8537 21.7675 34.5748V33.377C21.759 33.1446 21.7311 32.9354 21.684 32.7495C21.6369 32.5636 21.5705 32.4052 21.4848 32.2742C21.3991 32.1432 21.2921 32.0418 21.1635 31.97C21.0393 31.8982 20.8937 31.8623 20.7266 31.8623C20.5467 31.8623 20.3925 31.8982 20.264 31.97C20.1397 32.0418 20.0412 32.1538 19.9684 32.3059C19.8956 32.4538 19.8399 32.646 19.8013 32.8826C19.767 33.115 19.7499 33.3939 19.7499 33.7192Z" fill="black"/>
  <path d="M27.7819 35.7853V32.6164C27.7819 32.4094 27.7562 32.2446 27.7048 32.1221C27.6577 31.9996 27.5849 31.9108 27.4863 31.8559C27.3921 31.7968 27.2764 31.7672 27.1393 31.7672C26.9851 31.7672 26.8566 31.7989 26.7538 31.8623C26.651 31.9256 26.5739 32.0165 26.5225 32.1348C26.4754 32.2531 26.4518 32.3946 26.4518 32.5594H24.6462C24.6462 32.2636 24.704 31.9848 24.8197 31.7228C24.9354 31.4566 25.1046 31.2243 25.3273 31.0257C25.5544 30.8229 25.8242 30.6644 26.137 30.5503C26.4497 30.4363 26.8009 30.3792 27.1908 30.3792C27.6534 30.3792 28.0646 30.4574 28.4245 30.6137C28.7843 30.7658 29.0692 31.0088 29.2791 31.3426C29.489 31.6763 29.5939 32.1094 29.5939 32.6418V35.6966C29.5939 36.0811 29.6132 36.3937 29.6518 36.6346C29.6946 36.8754 29.7567 37.0824 29.8381 37.2557V37.3634H28.0261C27.9447 37.1733 27.8826 36.9367 27.8397 36.6536C27.8012 36.3663 27.7819 36.0769 27.7819 35.7853ZM28.0004 33.1742L28.0068 34.2389H27.345C27.1736 34.2389 27.0237 34.2664 26.8952 34.3213C26.7709 34.3762 26.666 34.4544 26.5803 34.5558C26.4946 34.653 26.4304 34.7671 26.3876 34.898C26.349 35.029 26.3297 35.1684 26.3297 35.3163C26.3297 35.4811 26.3533 35.6205 26.4004 35.7346C26.4475 35.8487 26.5182 35.9353 26.6124 35.9945C26.7067 36.0536 26.8181 36.0832 26.9466 36.0832C27.1436 36.0832 27.315 36.0431 27.4606 35.9628C27.6063 35.8825 27.7134 35.7853 27.7819 35.6712C27.8547 35.5572 27.8783 35.4515 27.8526 35.3544L28.2638 36.0452C28.2124 36.2015 28.1417 36.3642 28.0518 36.5332C27.9661 36.7022 27.8569 36.8585 27.7241 37.0022C27.5913 37.1458 27.4264 37.2641 27.2293 37.3571C27.0365 37.4458 26.8074 37.4902 26.5418 37.4902C26.1691 37.4902 25.8285 37.4057 25.5201 37.2367C25.2117 37.0677 24.9675 36.8311 24.7876 36.5268C24.6119 36.2226 24.5241 35.8614 24.5241 35.4431C24.5241 35.0966 24.5798 34.784 24.6912 34.5051C24.8069 34.222 24.9782 33.9833 25.2052 33.7889C25.4323 33.5903 25.7193 33.4382 26.0663 33.3326C26.4133 33.227 26.8202 33.1742 27.2871 33.1742H28.0004Z" fill="black"/>
  <path d="M32.736 31.9256V37.3634H30.9305V30.506H32.6268L32.736 31.9256ZM32.479 33.7002L31.9843 33.7129C31.9757 33.2312 32.0185 32.7876 32.1128 32.3819C32.207 31.9763 32.3484 31.6235 32.5369 31.3235C32.7296 31.0236 32.9717 30.7912 33.2629 30.6264C33.5542 30.4616 33.8948 30.3792 34.2846 30.3792C34.5374 30.3792 34.7708 30.4257 34.985 30.5186C35.1992 30.6074 35.3855 30.7489 35.544 30.9433C35.7025 31.1334 35.8246 31.3827 35.9103 31.6911C35.996 31.9953 36.0388 32.3608 36.0388 32.7876V37.3634H34.2396V33.003C34.2396 32.6988 34.2097 32.4665 34.1497 32.3059C34.0897 32.1411 34.004 32.027 33.8927 31.9637C33.7813 31.8961 33.6442 31.8623 33.4814 31.8623C33.3101 31.8623 33.1601 31.9087 33.0316 32.0017C32.9031 32.0946 32.7982 32.2256 32.7168 32.3946C32.6397 32.5594 32.5797 32.7538 32.5369 32.9777C32.4983 33.2016 32.479 33.4425 32.479 33.7002ZM35.8653 33.5227L35.2163 33.6051C35.2078 33.153 35.2485 32.7326 35.3384 32.3439C35.4284 31.951 35.5676 31.6087 35.7561 31.3172C35.9446 31.0214 36.1802 30.7912 36.4629 30.6264C36.7499 30.4616 37.0819 30.3792 37.4589 30.3792C37.7416 30.3792 37.9986 30.4257 38.2299 30.5186C38.4613 30.6116 38.6583 30.7616 38.8211 30.9686C38.9882 31.1714 39.1145 31.4397 39.2002 31.7735C39.2902 32.1073 39.3351 32.5129 39.3351 32.9904V37.3634H37.5295V32.9967C37.5295 32.6883 37.4996 32.4517 37.4396 32.2869C37.3796 32.1221 37.2939 32.0101 37.1826 31.951C37.0712 31.8918 36.9384 31.8623 36.7842 31.8623C36.63 31.8623 36.495 31.9045 36.3794 31.989C36.268 32.0735 36.1737 32.1918 36.0966 32.3439C36.0195 32.4918 35.9617 32.6671 35.9231 32.87C35.8846 33.0685 35.8653 33.2861 35.8653 33.5227Z" fill="black"/>
  <path d="M44.0515 35.7156V30.506H45.8571V37.3634H44.1608L44.0515 35.7156ZM44.2572 34.3023L44.7584 34.2896C44.7584 34.7502 44.7134 35.1769 44.6234 35.5698C44.5335 35.9586 44.3985 36.2966 44.2186 36.5839C44.0387 36.8712 43.8138 37.0951 43.5439 37.2557C43.274 37.412 42.9549 37.4902 42.5865 37.4902C42.3038 37.4902 42.0425 37.4437 41.8026 37.3507C41.567 37.2536 41.3635 37.1036 41.1921 36.9008C41.0208 36.6937 40.8859 36.4297 40.7873 36.1085C40.6931 35.7832 40.646 35.3924 40.646 34.9361V30.506H42.4516V34.9551C42.4516 35.141 42.4687 35.3015 42.503 35.4367C42.5372 35.5677 42.5844 35.6755 42.6443 35.76C42.7043 35.8445 42.775 35.9057 42.8564 35.9438C42.9378 35.9776 43.0299 35.9945 43.1327 35.9945C43.4154 35.9945 43.6382 35.9226 43.8009 35.779C43.968 35.6311 44.0858 35.4304 44.1543 35.1769C44.2229 34.9192 44.2572 34.6276 44.2572 34.3023Z" fill="black"/>
  <path d="M50.2522 35.5128C50.2522 35.3945 50.2115 35.2889 50.1302 35.1959C50.0488 35.103 49.9202 35.0079 49.7446 34.9107C49.569 34.8093 49.3355 34.6973 49.0442 34.5748C48.7272 34.4481 48.4466 34.3171 48.2025 34.1819C47.9583 34.0467 47.7505 33.8967 47.5792 33.7319C47.4121 33.5671 47.2858 33.3791 47.2001 33.1678C47.1144 32.9566 47.0716 32.7157 47.0716 32.4453C47.0716 32.1538 47.1251 31.8855 47.2322 31.6404C47.3436 31.3911 47.5021 31.1735 47.7077 30.9876C47.9133 30.7975 48.1639 30.6496 48.4595 30.544C48.7594 30.4341 49.0956 30.3792 49.4683 30.3792C49.9909 30.3792 50.4386 30.4701 50.8113 30.6517C51.1882 30.8292 51.4774 31.0785 51.6787 31.3996C51.8801 31.7207 51.9807 32.0946 51.9807 32.5214H50.1816C50.1816 32.3693 50.158 32.2341 50.1109 32.1158C50.0638 31.9932 49.9866 31.8982 49.8796 31.8306C49.7767 31.763 49.6375 31.7292 49.4619 31.7292C49.3334 31.7292 49.2177 31.7566 49.1149 31.8115C49.0164 31.8665 48.9371 31.9404 48.8772 32.0334C48.8215 32.1221 48.7936 32.2277 48.7936 32.3503C48.7936 32.4432 48.8129 32.5256 48.8515 32.5974C48.8943 32.6693 48.9607 32.739 49.0507 32.8066C49.1406 32.8742 49.2606 32.946 49.4105 33.0221C49.5647 33.0939 49.7532 33.1742 49.9759 33.2629C50.4386 33.4319 50.8177 33.6136 51.1133 33.8079C51.4131 34.0023 51.6359 34.2305 51.7815 34.4924C51.9272 34.7502 52 35.0692 52 35.4494C52 35.7536 51.94 36.0304 51.8201 36.2797C51.7044 36.529 51.5352 36.7444 51.3125 36.9261C51.0897 37.1078 50.822 37.2472 50.5093 37.3444C50.2008 37.4416 49.856 37.4902 49.4747 37.4902C48.9136 37.4902 48.4402 37.3803 48.0547 37.1606C47.6691 36.9409 47.3779 36.6599 47.1808 36.3177C46.9837 35.9712 46.8852 35.6121 46.8852 35.2403H48.5944C48.603 35.4684 48.6458 35.648 48.7229 35.779C48.8043 35.9057 48.9136 35.9945 49.0507 36.0452C49.1877 36.0959 49.3398 36.1212 49.5069 36.1212C49.6739 36.1212 49.811 36.1001 49.9181 36.0578C50.0295 36.0114 50.113 35.9417 50.1687 35.8487C50.2244 35.7515 50.2522 35.6396 50.2522 35.5128Z" fill="black"/>
  <ellipse cx="6.90545" cy="19.1919" rx="5.26385" ry="5.19189" fill="#0082BA"/>
  <path d="M5.58944 16.5958L9.53745 20.4898L8.22144 21.7878L4.27344 17.8938L5.58944 16.5958Z" fill="white"/>
  <path d="M8.22201 16.5958L4.274 20.4898L5.59 21.7878L9.53801 17.8938L8.22201 16.5958Z" fill="white"/>
  <ellipse cx="46.384" cy="19.1919" rx="5.26385" ry="5.19189" fill="#FF5E62"/>
  <path d="M47.3146 16.4382V21.9452H45.4535L45.4535 16.4382L47.3146 16.4382Z" fill="white"/>
  <path d="M49.1761 18.2742L43.5928 18.2742L43.5928 20.1099H49.1761V18.2742Z" fill="white"/>
  <ellipse cx="20.0646" cy="19.1919" rx="5.26385" ry="5.19189" fill="#FFC721"/>
  <path d="M22.8568 18.2742L17.2734 18.2742L17.2734 20.1099L22.8568 20.1099V18.2742Z" fill="white"/>
  <ellipse cx="33.2238" cy="19.1919" rx="5.26385" ry="5.19189" fill="#00B052"/>
  <path d="M35.8557 16.5958L30.5918 16.5958L30.5918 18.4315L35.8557 18.4315V16.5958Z" fill="white"/>
  <path d="M35.8557 19.7295L30.5918 19.7295L30.5918 21.5652H35.8557V19.7295Z" fill="white"/>
  </svg>  
  <svg v-else width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2.79274 31.8242V39.9999H1V30.5059H2.65781L2.79274 31.8242ZM6.40393 33.7002V34.1565C6.40393 34.6931 6.35681 35.1684 6.26257 35.5825C6.16833 35.9923 6.02696 36.3409 5.83848 36.6282C5.65428 36.9113 5.42296 37.1268 5.14451 37.2747C4.86607 37.4183 4.54051 37.4901 4.16782 37.4901C3.82084 37.4901 3.52098 37.4099 3.26824 37.2493C3.01978 37.0845 2.80988 36.8585 2.63853 36.5712C2.46718 36.2839 2.3301 35.948 2.22729 35.5635C2.12448 35.179 2.04523 34.767 1.98954 34.3276V33.6178C2.04095 33.1488 2.11591 32.7178 2.21444 32.3249C2.31296 31.9277 2.4479 31.5834 2.61925 31.2918C2.79488 31.0003 3.00693 30.7764 3.25539 30.62C3.50813 30.4595 3.80799 30.3792 4.15497 30.3792C4.53194 30.3792 4.85964 30.4468 5.13809 30.582C5.42081 30.7172 5.65428 30.9221 5.83848 31.1968C6.02696 31.4672 6.16833 31.8115 6.26257 32.2298C6.35681 32.6481 6.40393 33.1382 6.40393 33.7002ZM4.60476 34.1565V33.7002C4.60476 33.3706 4.58549 33.0896 4.54693 32.8572C4.51266 32.6249 4.45697 32.4347 4.37987 32.2869C4.30276 32.139 4.20209 32.0312 4.07786 31.9636C3.95363 31.896 3.80156 31.8622 3.62165 31.8622C3.44601 31.8622 3.2918 31.896 3.159 31.9636C3.03049 32.0312 2.92554 32.1305 2.84415 32.2615C2.76276 32.3883 2.70064 32.5446 2.65781 32.7305C2.61497 32.9122 2.59141 33.1192 2.58712 33.3516V34.5938C2.59569 34.8684 2.6321 35.1114 2.69636 35.3226C2.7649 35.5339 2.87413 35.7029 3.02406 35.8297C3.174 35.9522 3.37747 36.0134 3.6345 36.0134C3.8187 36.0134 3.97077 35.9754 4.09071 35.8994C4.21494 35.8233 4.31561 35.7092 4.39272 35.5571C4.46983 35.405 4.52337 35.2128 4.55336 34.9804C4.58763 34.748 4.60476 34.4734 4.60476 34.1565Z" fill="white"/>
  <path d="M9.3083 32.0333V37.3634H7.50913V30.5059H9.20549L9.3083 32.0333ZM10.9468 30.4552L10.9211 32.2235C10.8397 32.2066 10.7412 32.1939 10.6256 32.1854C10.5142 32.177 10.4156 32.1728 10.33 32.1728C10.1286 32.1728 9.95729 32.2045 9.81593 32.2678C9.67456 32.327 9.55676 32.4178 9.46252 32.5404C9.37256 32.6587 9.30402 32.8065 9.2569 32.984C9.20978 33.1615 9.18193 33.3622 9.17337 33.5861L8.83281 33.4593C8.83281 33.0115 8.86708 32.6016 8.93562 32.2298C9.00844 31.8538 9.11553 31.5284 9.2569 31.2538C9.39826 30.9749 9.56747 30.7595 9.76452 30.6073C9.96586 30.4552 10.1929 30.3792 10.4456 30.3792C10.5313 30.3792 10.6213 30.3876 10.7155 30.4045C10.814 30.4172 10.8911 30.4341 10.9468 30.4552Z" fill="white"/>
  <path d="M11.4288 34.1692V33.7065C11.4288 33.1699 11.493 32.6946 11.6215 32.2805C11.7543 31.8664 11.9428 31.52 12.187 31.2411C12.4311 30.958 12.7246 30.7447 13.0673 30.601C13.4143 30.4531 13.8019 30.3792 14.2303 30.3792C14.663 30.3792 15.0506 30.4531 15.3933 30.601C15.7403 30.7447 16.0359 30.958 16.2801 31.2411C16.5285 31.52 16.7192 31.8664 16.852 32.2805C16.9848 32.6946 17.0512 33.1699 17.0512 33.7065V34.1692C17.0512 34.7015 16.9848 35.1748 16.852 35.5888C16.7192 36.0029 16.5285 36.3515 16.2801 36.6346C16.0359 36.9176 15.7425 37.131 15.3998 37.2747C15.0571 37.4183 14.6715 37.4901 14.2432 37.4901C13.8148 37.4901 13.4271 37.4183 13.0801 37.2747C12.7374 37.131 12.4419 36.9176 12.1934 36.6346C11.9449 36.3515 11.7543 36.0029 11.6215 35.5888C11.493 35.1748 11.4288 34.7015 11.4288 34.1692ZM13.2279 33.7065V34.1692C13.2279 34.4861 13.2493 34.7607 13.2922 34.9931C13.3393 35.2255 13.4057 35.4177 13.4914 35.5698C13.5813 35.7219 13.6884 35.8339 13.8126 35.9057C13.9369 35.9775 14.0804 36.0134 14.2432 36.0134C14.4059 36.0134 14.5516 35.9775 14.6801 35.9057C14.8086 35.8339 14.9136 35.7219 14.995 35.5698C15.0806 35.4177 15.1427 35.2255 15.1813 34.9931C15.2241 34.7607 15.2456 34.4861 15.2456 34.1692V33.7065C15.2456 33.3981 15.222 33.1277 15.1749 32.8953C15.1278 32.6587 15.0614 32.4664 14.9757 32.3185C14.89 32.1664 14.7829 32.0524 14.6544 31.9763C14.5302 31.896 14.3888 31.8559 14.2303 31.8559C14.0718 31.8559 13.9305 31.896 13.8062 31.9763C13.6863 32.0524 13.5813 32.1664 13.4914 32.3185C13.4057 32.4664 13.3393 32.6587 13.2922 32.8953C13.2493 33.1277 13.2279 33.3981 13.2279 33.7065Z" fill="white"/>
  <path d="M21.5555 35.8487V27.6286H23.3611V37.3634L21.729 37.3697L21.5555 35.8487ZM17.9507 34.1755V33.7192C17.9507 33.1615 17.9979 32.6735 18.0921 32.2552C18.1906 31.8369 18.3363 31.4883 18.529 31.2094C18.7218 30.9306 18.9574 30.7235 19.2359 30.5883C19.5186 30.4489 19.842 30.3792 20.2061 30.3792C20.536 30.3792 20.8251 30.4616 21.0736 30.6264C21.3263 30.7869 21.5405 31.013 21.7161 31.3045C21.8918 31.596 22.0331 31.9404 22.1402 32.3376C22.2473 32.7347 22.3223 33.1699 22.3651 33.6431V34.3023C22.3137 34.7501 22.2345 35.1684 22.1274 35.5571C22.0246 35.9458 21.8832 36.2839 21.7033 36.5712C21.5277 36.8585 21.3156 37.0845 21.0672 37.2493C20.8187 37.4099 20.5295 37.4901 20.1997 37.4901C19.8356 37.4901 19.5122 37.4183 19.2294 37.2747C18.951 37.131 18.7154 36.9197 18.5226 36.6409C18.3341 36.3578 18.1906 36.0113 18.0921 35.6015C17.9979 35.1874 17.9507 34.7121 17.9507 34.1755ZM19.7499 33.7192V34.1755C19.7499 34.4966 19.7649 34.7734 19.7949 35.0057C19.8292 35.2339 19.8827 35.4219 19.9555 35.5698C20.0326 35.7135 20.1333 35.8212 20.2575 35.893C20.3818 35.9606 20.536 35.9944 20.7202 35.9944C20.9601 35.9944 21.155 35.9353 21.3049 35.817C21.4548 35.6944 21.5662 35.5276 21.639 35.3163C21.7161 35.1008 21.759 34.8536 21.7675 34.5748V33.3769C21.759 33.1446 21.7311 32.9354 21.684 32.7495C21.6369 32.5636 21.5705 32.4052 21.4848 32.2742C21.3991 32.1432 21.2921 32.0418 21.1635 31.97C21.0393 31.8981 20.8937 31.8622 20.7266 31.8622C20.5467 31.8622 20.3925 31.8981 20.264 31.97C20.1397 32.0418 20.0412 32.1538 19.9684 32.3059C19.8956 32.4537 19.8399 32.646 19.8013 32.8826C19.767 33.115 19.7499 33.3938 19.7499 33.7192Z" fill="white"/>
  <path d="M27.7819 35.7853V32.6164C27.7819 32.4094 27.7562 32.2446 27.7048 32.1221C27.6577 31.9995 27.5849 31.9108 27.4863 31.8559C27.3921 31.7967 27.2764 31.7672 27.1393 31.7672C26.9851 31.7672 26.8566 31.7988 26.7538 31.8622C26.651 31.9256 26.5739 32.0164 26.5225 32.1347C26.4754 32.253 26.4518 32.3946 26.4518 32.5594H24.6462C24.6462 32.2636 24.704 31.9848 24.8197 31.7228C24.9354 31.4566 25.1046 31.2242 25.3273 31.0256C25.5544 30.8228 25.8242 30.6644 26.137 30.5503C26.4497 30.4362 26.8009 30.3792 27.1908 30.3792C27.6534 30.3792 28.0646 30.4574 28.4245 30.6137C28.7843 30.7658 29.0692 31.0087 29.2791 31.3425C29.489 31.6763 29.5939 32.1094 29.5939 32.6418V35.6966C29.5939 36.0811 29.6132 36.3937 29.6518 36.6346C29.6946 36.8754 29.7567 37.0824 29.8381 37.2556V37.3634H28.0261C27.9447 37.1733 27.8826 36.9366 27.8397 36.6536C27.8012 36.3663 27.7819 36.0768 27.7819 35.7853ZM28.0004 33.1741L28.0068 34.2389H27.345C27.1736 34.2389 27.0237 34.2663 26.8952 34.3213C26.7709 34.3762 26.666 34.4544 26.5803 34.5558C26.4946 34.6529 26.4304 34.767 26.3876 34.898C26.349 35.029 26.3297 35.1684 26.3297 35.3163C26.3297 35.4811 26.3533 35.6205 26.4004 35.7346C26.4475 35.8487 26.5182 35.9353 26.6124 35.9944C26.7067 36.0536 26.8181 36.0832 26.9466 36.0832C27.1436 36.0832 27.315 36.043 27.4606 35.9627C27.6063 35.8825 27.7134 35.7853 27.7819 35.6712C27.8547 35.5571 27.8783 35.4515 27.8526 35.3543L28.2638 36.0451C28.2124 36.2015 28.1417 36.3641 28.0518 36.5331C27.9661 36.7022 27.8569 36.8585 27.7241 37.0021C27.5913 37.1458 27.4264 37.2641 27.2293 37.3571C27.0365 37.4458 26.8074 37.4901 26.5418 37.4901C26.1691 37.4901 25.8285 37.4056 25.5201 37.2366C25.2117 37.0676 24.9675 36.831 24.7876 36.5268C24.6119 36.2226 24.5241 35.8613 24.5241 35.4431C24.5241 35.0966 24.5798 34.7839 24.6912 34.5051C24.8069 34.222 24.9782 33.9833 25.2052 33.7889C25.4323 33.5903 25.7193 33.4382 26.0663 33.3326C26.4133 33.227 26.8202 33.1741 27.2871 33.1741H28.0004Z" fill="white"/>
  <path d="M32.736 31.9256V37.3634H30.9305V30.5059H32.6268L32.736 31.9256ZM32.479 33.7002L31.9843 33.7128C31.9757 33.2312 32.0185 32.7875 32.1128 32.3819C32.207 31.9763 32.3484 31.6235 32.5369 31.3235C32.7296 31.0235 32.9717 30.7911 33.2629 30.6264C33.5542 30.4616 33.8948 30.3792 34.2846 30.3792C34.5374 30.3792 34.7708 30.4257 34.985 30.5186C35.1992 30.6073 35.3855 30.7489 35.544 30.9432C35.7025 31.1334 35.8246 31.3827 35.9103 31.6911C35.996 31.9953 36.0388 32.3608 36.0388 32.7875V37.3634H34.2396V33.003C34.2396 32.6988 34.2097 32.4664 34.1497 32.3059C34.0897 32.1411 34.004 32.027 33.8927 31.9636C33.7813 31.896 33.6442 31.8622 33.4814 31.8622C33.3101 31.8622 33.1601 31.9087 33.0316 32.0017C32.9031 32.0946 32.7982 32.2256 32.7168 32.3946C32.6397 32.5594 32.5797 32.7537 32.5369 32.9777C32.4983 33.2016 32.479 33.4424 32.479 33.7002ZM35.8653 33.5227L35.2163 33.6051C35.2078 33.153 35.2485 32.7326 35.3384 32.3439C35.4284 31.9509 35.5676 31.6087 35.7561 31.3172C35.9446 31.0214 36.1802 30.7911 36.4629 30.6264C36.7499 30.4616 37.0819 30.3792 37.4589 30.3792C37.7416 30.3792 37.9986 30.4257 38.2299 30.5186C38.4613 30.6116 38.6583 30.7616 38.8211 30.9686C38.9882 31.1714 39.1145 31.4397 39.2002 31.7735C39.2902 32.1073 39.3351 32.5129 39.3351 32.9903V37.3634H37.5295V32.9967C37.5295 32.6882 37.4996 32.4516 37.4396 32.2869C37.3796 32.1221 37.2939 32.0101 37.1826 31.9509C37.0712 31.8918 36.9384 31.8622 36.7842 31.8622C36.63 31.8622 36.495 31.9045 36.3794 31.989C36.268 32.0735 36.1737 32.1918 36.0966 32.3439C36.0195 32.4918 35.9617 32.6671 35.9231 32.8699C35.8846 33.0685 35.8653 33.2861 35.8653 33.5227Z" fill="white"/>
  <path d="M44.0515 35.7156V30.5059H45.8571V37.3634H44.1608L44.0515 35.7156ZM44.2572 34.3023L44.7584 34.2896C44.7584 34.7501 44.7134 35.1769 44.6234 35.5698C44.5335 35.9585 44.3985 36.2965 44.2186 36.5838C44.0387 36.8712 43.8138 37.0951 43.5439 37.2556C43.274 37.412 42.9549 37.4901 42.5865 37.4901C42.3038 37.4901 42.0425 37.4437 41.8026 37.3507C41.567 37.2535 41.3635 37.1035 41.1921 36.9007C41.0208 36.6937 40.8859 36.4296 40.7873 36.1085C40.6931 35.7832 40.646 35.3924 40.646 34.936V30.5059H42.4516V34.955C42.4516 35.141 42.4687 35.3015 42.503 35.4367C42.5372 35.5677 42.5844 35.6754 42.6443 35.7599C42.7043 35.8444 42.775 35.9057 42.8564 35.9437C42.9378 35.9775 43.0299 35.9944 43.1327 35.9944C43.4154 35.9944 43.6382 35.9226 43.8009 35.779C43.968 35.6311 44.0858 35.4304 44.1543 35.1769C44.2229 34.9191 44.2572 34.6276 44.2572 34.3023Z" fill="white"/>
  <path d="M50.2522 35.5128C50.2522 35.3945 50.2115 35.2888 50.1302 35.1959C50.0488 35.1029 49.9202 35.0079 49.7446 34.9107C49.569 34.8093 49.3355 34.6973 49.0442 34.5748C48.7272 34.448 48.4466 34.317 48.2025 34.1818C47.9583 34.0466 47.7505 33.8966 47.5792 33.7319C47.4121 33.5671 47.2858 33.3791 47.2001 33.1678C47.1144 32.9565 47.0716 32.7157 47.0716 32.4453C47.0716 32.1538 47.1251 31.8855 47.2322 31.6404C47.3436 31.3911 47.5021 31.1735 47.7077 30.9876C47.9133 30.7975 48.1639 30.6496 48.4595 30.544C48.7594 30.4341 49.0956 30.3792 49.4683 30.3792C49.9909 30.3792 50.4386 30.47 50.8113 30.6517C51.1882 30.8292 51.4774 31.0785 51.6787 31.3996C51.8801 31.7207 51.9807 32.0946 51.9807 32.5213H50.1816C50.1816 32.3692 50.158 32.234 50.1109 32.1157C50.0638 31.9932 49.9866 31.8981 49.8796 31.8305C49.7767 31.7629 49.6375 31.7291 49.4619 31.7291C49.3334 31.7291 49.2177 31.7566 49.1149 31.8115C49.0164 31.8664 48.9371 31.9404 48.8772 32.0333C48.8215 32.1221 48.7936 32.2277 48.7936 32.3502C48.7936 32.4432 48.8129 32.5256 48.8515 32.5974C48.8943 32.6692 48.9607 32.7389 49.0507 32.8065C49.1406 32.8741 49.2606 32.946 49.4105 33.022C49.5647 33.0939 49.7532 33.1741 49.9759 33.2629C50.4386 33.4319 50.8177 33.6136 51.1133 33.8079C51.4131 34.0023 51.6359 34.2304 51.7815 34.4924C51.9272 34.7501 52 35.0691 52 35.4494C52 35.7536 51.94 36.0304 51.8201 36.2796C51.7044 36.5289 51.5352 36.7444 51.3125 36.9261C51.0897 37.1078 50.822 37.2472 50.5093 37.3444C50.2008 37.4416 49.856 37.4901 49.4747 37.4901C48.9136 37.4901 48.4402 37.3803 48.0547 37.1606C47.6691 36.9409 47.3779 36.6599 47.1808 36.3177C46.9837 35.9712 46.8852 35.6121 46.8852 35.2402H48.5944C48.603 35.4684 48.6458 35.648 48.7229 35.779C48.8043 35.9057 48.9136 35.9944 49.0507 36.0451C49.1877 36.0958 49.3398 36.1212 49.5069 36.1212C49.6739 36.1212 49.811 36.1001 49.9181 36.0578C50.0295 36.0113 50.113 35.9416 50.1687 35.8487C50.2244 35.7515 50.2522 35.6395 50.2522 35.5128Z" fill="white"/>
  <ellipse cx="6.90545" cy="19.1919" rx="5.26385" ry="5.19189" fill="white"/>
  <path d="M5.58944 16.5958L9.53745 20.4898L8.22144 21.7878L4.27344 17.8938L5.58944 16.5958Z" fill="#12366C"/>
  <path d="M8.22201 16.5958L4.274 20.4898L5.59 21.7878L9.53801 17.8938L8.22201 16.5958Z" fill="#12366C"/>
  <ellipse cx="46.384" cy="19.1919" rx="5.26385" ry="5.19189" fill="white"/>
  <path d="M47.3146 16.4382V21.9452H45.4535L45.4535 16.4382L47.3146 16.4382Z" fill="#12366C"/>
  <path d="M49.1761 18.2742L43.5928 18.2742L43.5928 20.1099H49.1761V18.2742Z" fill="#12366C"/>
  <ellipse cx="20.0646" cy="19.1919" rx="5.26385" ry="5.19189" fill="white"/>
  <path d="M22.8568 18.2742L17.2734 18.2742L17.2734 20.1098L22.8568 20.1098V18.2742Z" fill="#12366C"/>
  <ellipse cx="33.2238" cy="19.1919" rx="5.26385" ry="5.19189" fill="white"/>
  <path d="M35.8557 16.5958L30.5918 16.5958L30.5918 18.4314L35.8557 18.4314V16.5958Z" fill="#12366C"/>
  <path d="M35.8557 19.7295L30.5918 19.7295L30.5918 21.5651H35.8557V19.7295Z" fill="#12366C"/>
  </svg>
  `
});

export default script;