

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'LogoutIcon',
  props: ['color'],
  template: `
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.874 12.2508C11.6467 14.8908 10.29 15.9687 7.32002 15.9687L7.22469 15.9687C3.94669 15.9687 2.63402 14.6561 2.63402 11.3781L2.63402 6.59675C2.63402 3.31875 3.94669 2.00608 7.22469 2.00608L7.32002 2.00608C10.268 2.00608 11.6247 3.06942 11.8667 5.66542" :stroke="color || '#FF2424'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.40076 9L15.7461 9" :stroke="color || '#FF2424'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.1107 11.4531L16.5674 8.99646L14.1107 6.53979" :stroke="color || '#FF2424'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;