

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'PenIcon',
  props: ['color'],
  template: `
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.0857 4.91356L15.0862 8.91425L6.39929 17.6016L2.83251 17.9953C2.35502 18.0482 1.95159 17.6444 2.00471 17.1669L2.40158 13.5975L11.0857 4.91356ZM17.5606 4.31793L15.6822 2.43946C15.0962 1.85351 14.146 1.85351 13.56 2.43946L11.7929 4.20668L15.7934 8.20737L17.5606 6.44015C18.1465 5.85389 18.1465 4.90388 17.5606 4.31793Z" :fill="color || '#fff'"/>
  </svg>
  `
});

export default script;