

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'CheckmarkIcon',
  props: ['color'],
  template: `
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.2688 1.82738L4.28705 10.8138L0.17041 6.69501L1.22577 5.63911L4.28705 8.69448L12.2134 0.771484L13.2688 1.82738Z" :fill="color || '#12366C'"/>
  </svg>
  `
});

export default script;