import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'AlertIcon',
  template: `
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.99967 14.6666C11.6816 14.6666 14.6663 11.6818 14.6663 7.99992C14.6663 4.31802 11.6816 1.33325 7.99967 1.33325C4.31778 1.33325 1.33301 4.31802 1.33301 7.99992C1.33301 11.6818 4.31778 14.6666 7.99967 14.6666Z" stroke="#FF725E" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 5.33325V7.99992" stroke="#FF725E" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 10.6667H8.00667" stroke="#FF725E" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  `
});

export default script;