

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'QuizIcon',
  props: ['color'],
  template: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.75 21.5H2.5V5.25C2.5 4.5625 1.9375 4 1.25 4C0.5625 4 0 4.5625 0 5.25V21.5C0 22.875 1.125 24 2.5 24H18.75C19.4375 24 20 23.4375 20 22.75C20 22.0625 19.4375 21.5 18.75 21.5Z" :fill="color || 'white'"/>
  <path d="M21.5 0H6.5C5.125 0 4 1.125 4 2.5V17.5C4 18.875 5.125 20 6.5 20H21.5C22.875 20 24 18.875 24 17.5V2.5C24 1.125 22.875 0 21.5 0ZM14.0125 16.25C13.275 16.25 12.7 15.6625 12.7 14.9375C12.7 14.2 13.2875 13.6375 14.0125 13.6375C14.75 13.6375 15.3125 14.2 15.3125 14.9375C15.3 15.6625 14.75 16.25 14.0125 16.25ZM17.1375 8.5375C16.35 9.7 15.6 10.05 15.1875 10.8C15.0875 10.975 15.025 11.125 14.9875 11.4125C14.925 11.9 14.5375 12.2625 14.05 12.2625H14.0125C13.4625 12.2625 13.025 11.7875 13.075 11.2375C13.1125 10.8875 13.1875 10.525 13.3875 10.1875C13.9 9.275 14.8625 8.7375 15.425 7.9375C16.025 7.0875 15.6875 5.5125 14 5.5125C13.2375 5.5125 12.7375 5.9125 12.425 6.3875C12.1875 6.75 11.7125 6.875 11.3125 6.7C10.7875 6.475 10.5625 5.825 10.8875 5.3625C11.525 4.4375 12.5875 3.75 13.9875 3.75C15.525 3.75 16.5875 4.45 17.125 5.325C17.5875 6.0875 17.85 7.4875 17.1375 8.5375Z" :fill="color || 'white'"/>
  </svg>
  `
});

export default script;