import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'DownTableArrowIcon',
  template: `
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2.96509 7.215L6.00009 10.25L9.03509 7.215" :stroke="color || '#01BCB5'" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6 1.75001L6 10.165" :stroke="color || '#01BCB5'" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;