

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'PaymentIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.0912 19.5036C17.8453 19.5036 16.8317 18.49 16.8317 17.2441V13.4918C16.8317 12.2459 17.8453 11.2323 19.0912 11.2323H24.9786C25.0143 11.2323 25.0497 11.2334 25.085 11.235V7.49703C25.085 6.53484 24.305 5.75484 23.3428 5.75484H1.74225C0.780004 5.75478 0 6.53478 0 7.49697V23.2389C0 24.201 0.780004 24.9811 1.74225 24.9811H23.3428C24.305 24.9811 25.085 24.2011 25.085 23.2389V19.5009C25.0497 19.5025 25.0143 19.5036 24.9786 19.5036H19.0912Z" fill="white"/>
  <path d="M24.9787 12.4704H19.0912C18.5271 12.4704 18.0698 12.9277 18.0698 13.4918V17.2441C18.0698 17.8082 18.5271 18.2655 19.0912 18.2655H24.9787C25.5427 18.2655 26.0001 17.8083 26.0001 17.2441V13.4918C26.0001 12.9276 25.5427 12.4704 24.9787 12.4704ZM21.1199 16.8421C20.3057 16.8421 19.6457 16.1821 19.6457 15.3679C19.6457 14.5537 20.3057 13.8937 21.1199 13.8937C21.9341 13.8937 22.5941 14.5537 22.5941 15.3679C22.5941 16.1821 21.9341 16.8421 21.1199 16.8421Z" fill="white"/>
  <path d="M20.3092 2.56348C19.9194 1.38372 18.6471 0.743322 17.4674 1.1331L8.61646 4.0572H20.8027L20.3092 2.56348Z" fill="white"/>
  </svg>
  `
});

export default script;