

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'MainIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13 19.5V16.25" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.9092 3.05646L3.40167 9.06896C2.55667 9.74063 2.015 11.1598 2.19917 12.2215L3.64 20.8448C3.9 22.3831 5.37334 23.629 6.93334 23.629H19.0667C20.6158 23.629 22.1 22.3723 22.36 20.8448L23.8008 12.2215C23.9742 11.1598 23.4325 9.74063 22.5983 9.06896L15.0908 3.0673C13.9317 2.13563 12.0575 2.13563 10.9092 3.05646Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
  `
});

export default script;