

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'CancelIcon',
  props: ['color'],
  template: `
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15 5L5 15" :stroke="color || '#000'" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5 5L15 15" :stroke="color || '#000'" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;