

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'ChildrenIcon',
  props: ['color'],
  template: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16 12.1847V17.1941C16 17.6372 15.9529 18.0557 15.8469 18.4372C15.4113 20.2465 13.975 21.3789 11.9971 21.3789H8.7947L5.23915 23.8528C4.70934 24.2343 4.00294 23.8282 4.00294 23.1635V21.3789C2.80206 21.3789 1.80132 20.9604 1.1067 20.2342C0.400294 19.4957 0 18.4495 0 17.1941V12.1847C0 9.84621 1.38926 8.23385 3.53201 8.02462C3.68506 8.01231 3.83812 8 4.00294 8H11.9971C14.3988 8 16 9.67389 16 12.1847Z" fill="white"/>
  <path d="M19 16C20.4941 16 21.7529 15.5059 22.6235 14.6235C23.5059 13.7529 24 12.4941 24 11V5C24 2.23529 21.7647 0 19 0H9C6.23529 0 4 2.23529 4 5V5.88235C4 6.21176 4.25882 6.47059 4.58824 6.47059H12.4588C15.6471 6.47059 18.2235 9.04706 18.2235 12.2353V15.4118C18.2235 15.7412 18.4824 16 18.8118 16H19Z" fill="white"/>
  </svg>
  `
});

export default script;