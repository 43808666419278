

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'SelectIcon',
  props: ['color'],
  template: `
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.5 5.25L7 8.75L10.5 5.25" stroke="#01BCB5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;