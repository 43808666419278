

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'PromocodeIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M23.0279 26C23.6806 26 24.2098 25.4709 24.2098 24.8182V1.18189C24.2098 0.703887 23.9219 0.272918 23.4802 0.0900523C23.0384 -0.0929714 22.5302 0.00819193 22.1922 0.346191L19.6852 2.85321L17.1782 0.346191C16.7167 -0.115347 15.9684 -0.115347 15.5069 0.346191L12.9999 2.85313L10.4929 0.34627C10.0314 -0.115268 9.28321 -0.115189 8.8216 0.34627L6.31458 2.85329L3.80763 0.346191C3.46955 0.00819193 2.96122 -0.0929714 2.51961 0.0900523C2.07809 0.272918 1.79004 0.703887 1.79004 1.18189V24.8182C1.79004 25.4709 2.31926 26 2.97185 26H23.0279ZM16.8085 14.7271C17.3496 14.7271 17.7879 14.8615 18.1111 15.1267C18.3674 15.337 18.673 15.7402 18.673 16.4668V18.3665C18.673 19.4721 17.9934 20.1063 16.8086 20.1063C15.6234 20.1063 14.9437 19.4721 14.9437 18.3665V16.4668C14.9437 15.3775 15.6408 14.7271 16.8085 14.7271ZM14.8592 10.1557C14.9553 9.9504 15.1542 9.82812 15.3932 9.82812C15.7142 9.82812 16.0578 10.0906 16.0578 10.4811C16.0578 10.5903 16.0348 10.6894 15.9874 10.7841L11.2494 20.047C11.1381 20.2705 10.9207 20.4058 10.6695 20.4061H10.6688C10.3219 20.4058 10.0289 20.1183 10.0289 19.7784C10.0289 19.6804 10.0525 19.5828 10.0991 19.4885L14.8592 10.1557ZM7.32684 12.0431C7.32684 10.9375 8.0063 10.3035 9.19095 10.3035C10.3765 10.3035 11.0561 10.9375 11.0561 12.0431V13.9423C11.0561 15.0483 10.3764 15.6826 9.19119 15.6826C8.00646 15.6826 7.32692 15.0484 7.32684 13.9425V12.0431Z" fill="white"/>
  <path d="M16.8086 18.9133C17.3456 18.9133 17.4179 18.6369 17.4179 18.3666V16.4671C17.4179 16.1964 17.3455 15.9197 16.8086 15.9197C16.6636 15.9197 16.4661 15.942 16.3492 16.0486C16.2579 16.1318 16.2116 16.2725 16.2117 16.4669V18.3667C16.2116 18.5611 16.2579 18.7017 16.349 18.7846C16.466 18.891 16.6635 18.9133 16.8086 18.9133Z" fill="white"/>
  <path d="M9.19145 14.4896C9.7287 14.4894 9.80119 14.2129 9.80119 13.9424V12.0431C9.80111 11.7725 9.72862 11.4959 9.19137 11.4959C8.66657 11.4959 8.59424 11.7589 8.59424 12.0431V13.9423C8.59424 14.2264 8.66657 14.4894 9.19145 14.4896Z" fill="white"/>
  </svg>
  `
});

export default script;