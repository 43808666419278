

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'AutopostIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.39258 15.1667H4.33341C3.14175 15.1667 2.16675 16.1417 2.16675 17.3333V23.8333H9.39258V15.1667Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.4408 10.8333H11.5483C10.3566 10.8333 9.38159 11.8083 9.38159 13V23.8333H16.6074V13C16.6074 11.8083 15.6433 10.8333 14.4408 10.8333Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M21.6666 18.4167H16.6074V23.8333H23.8333V20.5833C23.8333 19.3917 22.8583 18.4167 21.6666 18.4167Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.5633 2.24251L14.1375 3.39084C14.2133 3.55334 14.4192 3.705 14.5925 3.72667L15.6325 3.90001C16.2933 4.00834 16.4558 4.49582 15.9792 4.96165L15.1667 5.77415C15.0258 5.91498 14.95 6.17499 14.9933 6.35916L15.2208 7.35584C15.405 8.14668 14.9825 8.45001 14.2892 8.03835L13.3142 7.46418C13.1408 7.35585 12.8483 7.35585 12.675 7.46418L11.7 8.03835C11.0067 8.45001 10.5842 8.14668 10.7683 7.35584L10.9958 6.35916C11.0392 6.17499 10.9633 5.90415 10.8225 5.77415L10.0208 4.97249C9.54417 4.49583 9.69584 4.01915 10.3675 3.91082L11.4075 3.73751C11.5808 3.70501 11.7867 3.55335 11.8625 3.40168L12.4367 2.25332C12.7508 1.62498 13.2492 1.62501 13.5633 2.24251Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;