import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'SendIcon',
  props: ['color'],
  template: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 2L11 13" :stroke="color || 'black'" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22 2L15 22L11 13L2 9L22 2Z" :stroke="color || 'black'" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;