

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'SubscriberIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12.9999 13C15.9915 13 18.4166 10.5749 18.4166 7.58334C18.4166 4.5918 15.9915 2.16667 12.9999 2.16667C10.0084 2.16667 7.58325 4.5918 7.58325 7.58334C7.58325 10.5749 10.0084 13 12.9999 13Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M22.3058 23.8333C22.3058 19.6408 18.1349 16.25 12.9999 16.25C7.86492 16.25 3.69409 19.6408 3.69409 23.8333" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;