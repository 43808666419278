

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'MoreIcon',
  props: ['color'],
  template: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3 12H21" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3 6H21" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3 18H21" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;