

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'PartnerIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.4999 7.75668C19.4349 7.74584 19.3591 7.74584 19.2941 7.75668C17.7991 7.70251 16.6074 6.47834 16.6074 4.96167C16.6074 3.41251 17.8533 2.16667 19.4024 2.16667C20.9516 2.16667 22.1974 3.42334 22.1974 4.96167C22.1866 6.47834 20.9949 7.70251 19.4999 7.75668Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.3841 15.6433C19.8682 15.8925 21.5041 15.6325 22.6524 14.8633C24.1799 13.845 24.1799 12.1767 22.6524 11.1583C21.4932 10.3892 19.8357 10.1292 18.3516 10.3892" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.46752 7.75668C6.53252 7.74584 6.60835 7.74584 6.67335 7.75668C8.16835 7.70251 9.36002 6.47834 9.36002 4.96167C9.36002 3.41251 8.11419 2.16667 6.56502 2.16667C5.01585 2.16667 3.77002 3.42334 3.77002 4.96167C3.78085 6.47834 4.97252 7.70251 6.46752 7.75668Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.58339 15.6433C6.09922 15.8925 4.46339 15.6325 3.31506 14.8633C1.78756 13.845 1.78756 12.1767 3.31506 11.1583C4.47423 10.3892 6.13172 10.1292 7.61589 10.3892" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.9999 15.8492C12.9349 15.8383 12.8591 15.8383 12.7941 15.8492C11.2991 15.795 10.1074 14.5708 10.1074 13.0542C10.1074 11.505 11.3533 10.2592 12.9024 10.2592C14.4516 10.2592 15.6974 11.5158 15.6974 13.0542C15.6866 14.5708 14.4949 15.8058 12.9999 15.8492Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.84753 19.2617C8.32003 20.28 8.32003 21.9483 9.84753 22.9667C11.5809 24.1258 14.4192 24.1258 16.1525 22.9667C17.68 21.9483 17.68 20.28 16.1525 19.2617C14.43 18.1133 11.5809 18.1133 9.84753 19.2617Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
  `
});

export default script;