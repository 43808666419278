

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'PolicyIcon',
  props: ['color'],
  template: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20.59 10.5501V7.12006C20.59 5.89006 19.65 4.53006 18.5 4.10006L13.51 2.23006C12.68 1.92006 11.32 1.92006 10.49 2.23006L5.50003 4.11006C4.35003 4.54006 3.41003 5.90006 3.41003 7.12006V14.5501C3.41003 15.7301 4.19003 17.2801 5.14003 17.9901L9.44003 21.2001C10.14 21.7401 11.07 22.0001 12 22.0001" stroke="#01BCB5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z" stroke="#01BCB5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M20.9955 21H21.0045" stroke="#01BCB5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;