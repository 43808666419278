

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'DeleteIcon',
  props: ['color'],
  template: `
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5 5.83333H4.16667V16.6667C4.16667 17.1087 4.34226 17.5326 4.65482 17.8452C4.96738 18.1577 5.39131 18.3333 5.83333 18.3333H14.1667C14.6087 18.3333 15.0326 18.1577 15.3452 17.8452C15.6577 17.5326 15.8333 17.1087 15.8333 16.6667V5.83333H5ZM13.8483 3.33333L12.5 1.66667H7.5L6.15167 3.33333H2.5V5H17.5V3.33333H13.8483Z" fill="#FF2424"/>
  </svg>
  `
});

export default script;