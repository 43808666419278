

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'RussianIcon',
  template: `
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_892_18619)">
  <path d="M35 60C48.8071 60 60 48.8071 60 35C60 21.1929 48.8071 10 35 10C21.1929 10 10 21.1929 10 35C10 48.8071 21.1929 60 35 60Z" fill="white"/>
  <path d="M58.445 43.6956C59.45 40.9873 60 38.058 60 35C60 31.942 59.45 29.0127 58.445 26.3044H11.555C10.5501 29.0127 10 31.942 10 35C10 38.058 10.5501 40.9873 11.555 43.6956L35 45.8695L58.445 43.6956Z" fill="#1067CF"/>
  <path d="M35 60C45.7491 60 54.9126 53.2156 58.445 43.6956H11.5549C15.0873 53.2156 24.2508 60 35 60Z" fill="#E6072F"/>
  </g>
  <defs>
  <filter id="filter0_d_892_18619" x="0" y="0" width="70" height="70" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset/>
  <feGaussianBlur stdDeviation="5"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_892_18619"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_892_18619" result="shape"/>
  </filter>
  </defs>
  </svg>
  `
});

export default script;