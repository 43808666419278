import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'DownArrowIcon',
  props: ['color'],
  template: `
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4 6L8 10L12 6" :stroke="color || '#22396D'" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;