import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'LeftArrowIcon',
  props: ['color'],
  template: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.57 5.92999L3.5 12L9.57 18.07" :stroke="color || '#01BCB5'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M20.5 12H3.67004" :stroke="color || '#01BCB5'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
  `
});

export default script;