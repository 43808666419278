

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'ReferralIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M24.3499 16.9623C24.5485 16.1012 24.6552 15.2235 24.6552 14.3448C24.6552 10.2678 22.5792 6.55514 19.1015 4.41418C18.8895 4.28418 18.6142 4.35007 18.4847 4.56076C18.3547 4.7719 18.4206 5.04759 18.6313 5.17759C21.8418 7.15449 23.7586 10.5811 23.7586 14.3448C23.7586 15.1186 23.669 15.8918 23.5018 16.6521C23.2951 16.6091 23.0813 16.5862 22.8621 16.5862C21.1317 16.5862 19.7242 17.9942 19.7242 19.7241C19.7242 21.454 21.1317 22.8621 22.8621 22.8621C24.5924 22.8621 26 21.454 26 19.7241C26 18.5322 25.3316 17.4935 24.3499 16.9623Z" fill="white"/>
  <path d="M17.1111 24.2885C15.8044 24.8291 14.4215 25.1035 13 25.1035C10.1378 25.1035 7.38445 23.9491 5.37169 21.9243C5.93024 21.3572 6.27586 20.5808 6.27586 19.7241C6.27586 17.9942 4.86828 16.5862 3.13793 16.5862C1.40759 16.5862 0 17.9942 0 19.7241C0 21.454 1.40759 22.8621 3.13793 22.8621C3.68752 22.8621 4.20393 22.7191 4.65355 22.4698C6.84069 24.7161 9.85938 26 13 26C14.5398 26 16.0384 25.7028 17.4541 25.1165C17.6827 25.0219 17.7916 24.7596 17.697 24.5306C17.6024 24.3024 17.3398 24.1939 17.1111 24.2885Z" fill="white"/>
  <path d="M1.79315 13.992C1.80391 13.9929 1.81467 13.9934 1.82498 13.9934C2.05853 13.9934 2.25488 13.8132 2.27191 13.5765C2.59109 9.05383 5.67253 5.27441 9.99122 4.02193C10.3741 5.32283 11.5768 6.27586 13.0001 6.27586C14.7304 6.27586 16.138 4.86783 16.138 3.13793C16.138 1.40803 14.7304 0 13.0001 0C11.2737 0 9.86884 1.40176 9.86257 3.12672C5.11936 4.44779 1.72636 8.57103 1.3776 13.5137C1.36012 13.7603 1.54615 13.975 1.79315 13.992Z" fill="white"/>
  </svg>
  `
});

export default script;