

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'AdvertisingIcon',
  props: ['color'],
  template: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.88 18.1481V16.0781" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round"/>
  <path d="M12 18.1478V14.0078" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round"/>
  <path d="M17.12 18.1497V11.9297" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round"/>
  <path d="M17.12 5.85156L16.66 6.39156C14.11 9.37156 10.69 11.4816 6.88 12.4316" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round"/>
  <path d="M14.19 5.85156H17.12V8.77156" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;