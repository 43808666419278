

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'YoomoneyIcon',
  props: ['selected'],
  template: `
  <svg v-if="!selected" width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33.0293 11.5657C24.5008 11.5657 17.6475 18.5112 17.6475 27.0001C17.6475 35.559 24.5682 42.4346 33.0273 42.4346C41.4863 42.4346 48.4132 35.4891 48.4132 27.0001C48.4132 18.5112 41.4884 11.5657 33.0293 11.5657ZM33.0293 32.7531C29.8832 32.7531 27.2973 30.157 27.2973 27.0001C27.2973 23.8433 29.8832 21.2472 33.0293 21.2472C36.1754 21.2472 38.7624 23.8433 38.7624 27.0001C38.6919 30.157 36.1754 32.7531 33.0293 32.7531Z" fill="#8B3FFD"/>
  <path d="M17.6478 16.0498V38.4997H12.1978L5.20654 16.0498H17.6478Z" fill="#8B3FFD"/>
  </svg>
  <svg v-else width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33.0293 11.5657C24.5008 11.5657 17.6475 18.5112 17.6475 27.0001C17.6475 35.559 24.5682 42.4346 33.0273 42.4346C41.4863 42.4346 48.4132 35.4891 48.4132 27.0001C48.4132 18.5112 41.4884 11.5657 33.0293 11.5657ZM33.0293 32.7531C29.8832 32.7531 27.2973 30.157 27.2973 27.0001C27.2973 23.8433 29.8832 21.2472 33.0293 21.2472C36.1754 21.2472 38.7624 23.8433 38.7624 27.0001C38.6919 30.157 36.1754 32.7531 33.0293 32.7531Z" fill="white"/>
  <path d="M17.6478 16.0498V38.4997H12.1978L5.20654 16.0498H17.6478Z" fill="white"/>
  </svg>
  `
});

export default script;