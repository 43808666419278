

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'PaymentIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.6475 18.2654V20.4646C11.6475 22.3279 9.91413 23.8337 7.77996 23.8337C5.6458 23.8337 3.90161 22.3279 3.90161 20.4646V18.2654C3.90161 20.1287 5.63496 21.4504 7.77996 21.4504C9.91413 21.4504 11.6475 20.1179 11.6475 18.2654Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.6458 15.2858C11.6458 15.8275 11.4941 16.3258 11.2341 16.7591C10.595 17.81 9.28411 18.4708 7.76745 18.4708C6.25078 18.4708 4.93993 17.7991 4.30077 16.7591C4.04077 16.3258 3.88916 15.8275 3.88916 15.2858C3.88916 14.3541 4.32247 13.52 5.0158 12.9133C5.71997 12.2958 6.6841 11.9275 7.7566 11.9275C8.8291 11.9275 9.7933 12.3066 10.4975 12.9133C11.2125 13.5091 11.6458 14.3541 11.6458 15.2858Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.6475 15.2858V18.265C11.6475 20.1283 9.91413 21.45 7.77996 21.45C5.6458 21.45 3.90161 20.1175 3.90161 18.265V15.2858C3.90161 13.4225 5.63496 11.9167 7.77996 11.9167C8.85246 11.9167 9.81666 12.2958 10.5208 12.9025C11.2142 13.5091 11.6475 14.3542 11.6475 15.2858Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M23.8334 11.8841V14.1159C23.8334 14.7117 23.3567 15.1992 22.7501 15.2208H20.6267C19.4567 15.2208 18.3842 14.365 18.2867 13.195C18.2217 12.5125 18.4817 11.8733 18.9367 11.4292C19.3375 11.0175 19.8901 10.7792 20.4967 10.7792H22.7501C23.3567 10.8008 23.8334 11.2883 23.8334 11.8841Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.16675 11.375V9.20833C2.16675 6.26167 3.94342 4.20333 6.70592 3.85667C6.98758 3.81333 7.28008 3.79167 7.58341 3.79167H17.3334C17.6151 3.79167 17.8859 3.80249 18.1459 3.84582C20.9409 4.17082 22.7501 6.24 22.7501 9.20833V10.7792H20.4967C19.8901 10.7792 19.3376 11.0175 18.9367 11.4292C18.4817 11.8733 18.2218 12.5125 18.2868 13.195C18.3843 14.365 19.4567 15.2208 20.6267 15.2208H22.7501V16.7917C22.7501 20.0417 20.5834 22.2083 17.3334 22.2083H14.6251" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>  
  `
});

export default script;