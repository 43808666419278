

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'HelpIcon',
  props: ['color'],
  template: `
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.0467 7.50016L9.44671 8.1135C8.96671 8.5935 8.66671 9.00016 8.66671 10.0002H7.33337V9.66683C7.33337 8.92683 7.63337 8.26016 8.11337 7.78016L8.94004 6.94016C9.18671 6.70016 9.33337 6.36683 9.33337 6.00016C9.33337 5.64654 9.1929 5.3074 8.94285 5.05735C8.6928 4.80731 8.35366 4.66683 8.00004 4.66683C7.64642 4.66683 7.30728 4.80731 7.05723 5.05735C6.80718 5.3074 6.66671 5.64654 6.66671 6.00016H5.33337C5.33337 5.29292 5.61433 4.61464 6.11442 4.11454C6.61452 3.61445 7.2928 3.3335 8.00004 3.3335C8.70728 3.3335 9.38556 3.61445 9.88566 4.11454C10.3858 4.61464 10.6667 5.29292 10.6667 6.00016C10.6657 6.56232 10.4429 7.10138 10.0467 7.50016ZM8.66671 12.6668H7.33337V11.3335H8.66671V12.6668ZM8.00004 1.3335C7.12456 1.3335 6.25766 1.50593 5.44882 1.84097C4.63998 2.176 3.90505 2.66706 3.286 3.28612C2.03575 4.53636 1.33337 6.23205 1.33337 8.00016C1.33337 9.76827 2.03575 11.464 3.286 12.7142C3.90505 13.3333 4.63998 13.8243 5.44882 14.1594C6.25766 14.4944 7.12456 14.6668 8.00004 14.6668C9.76815 14.6668 11.4638 13.9645 12.7141 12.7142C13.9643 11.464 14.6667 9.76827 14.6667 8.00016C14.6667 4.3135 11.6667 1.3335 8.00004 1.3335Z" :fill="color || '#7686AB'"/>
  </svg>
  `
});

export default script;