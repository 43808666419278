

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'MinMaxIcon',
  props: ['color'],
  template: `
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.12532 2.39165L6.12533 11.6083C6.12533 12.4833 5.75199 12.8333 4.82449 12.8333L2.46783 12.8333C1.54033 12.8333 1.16699 12.4833 1.16699 11.6083L1.16699 2.39165C1.16699 1.51665 1.54032 1.16665 2.46782 1.16665L4.82449 1.16665C5.75199 1.16665 6.12532 1.51665 6.12532 2.39165Z" stroke="#01BCB5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.8333 6.47498L12.8333 11.6083C12.8333 12.4833 12.46 12.8333 11.5325 12.8333L9.17583 12.8333C8.24833 12.8333 7.875 12.4833 7.875 11.6083L7.875 6.47498C7.875 5.59998 8.24833 5.24998 9.17583 5.24998L11.5325 5.24998C12.46 5.24998 12.8333 5.59998 12.8333 6.47498Z" stroke="#01BCB5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;