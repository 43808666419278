

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'TransactionIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13 6.02333H23.8333" :stroke="color || '#22396D'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.405 2.16667H21.4283C23.3567 2.16667 23.8333 2.64334 23.8333 4.55V9.0025C23.8333 10.9092 23.3567 11.3858 21.4283 11.3858H15.405C13.4767 11.3858 13 10.9092 13 9.0025V4.55C13 2.64334 13.4767 2.16667 15.405 2.16667Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.16675 18.4817H13.0001" :stroke="color || '#22396D'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.57175 14.625H10.5951C12.5234 14.625 13.0001 15.1017 13.0001 17.0083V21.4608C13.0001 23.3675 12.5234 23.8442 10.5951 23.8442H4.57175C2.64342 23.8442 2.16675 23.3675 2.16675 21.4608V17.0083C2.16675 15.1017 2.64342 14.625 4.57175 14.625Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M23.8333 16.25C23.8333 20.4425 20.4425 23.8333 16.25 23.8333L17.3875 21.9375" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.16675 9.75C2.16675 5.5575 5.55758 2.16667 9.75008 2.16667L8.61259 4.0625" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
  `
});

export default script;