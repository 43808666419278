

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'TariffIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.55 6.5C4.03283 6.5 3.53684 6.29455 3.17114 5.92886C2.80545 5.56316 2.6 5.06717 2.6 4.55C2.6 4.03283 2.80545 3.53684 3.17114 3.17114C3.53684 2.80545 4.03283 2.6 4.55 2.6C5.06717 2.6 5.56316 2.80545 5.92886 3.17114C6.29455 3.53684 6.5 4.03283 6.5 4.55C6.5 5.06717 6.29455 5.56316 5.92886 5.92886C5.56316 6.29455 5.06717 6.5 4.55 6.5ZM25.233 12.454L13.533 0.754C13.065 0.286 12.415 0 11.7 0H2.6C1.157 0 0 1.157 0 2.6V11.7C0 12.415 0.286 13.065 0.767 13.533L12.454 25.233C12.935 25.701 13.585 26 14.3 26C15.015 26 15.665 25.701 16.133 25.233L25.233 16.133C25.714 15.665 26 15.015 26 14.3C26 13.572 25.701 12.922 25.233 12.454Z" fill="white"/>
  </svg>
  `
});

export default script;