

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'VisaIcon',
  props: ['color'],
  template: `
  <svg width="60" height="20" viewBox="0 0 60 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_892_18334)">
  <path d="M55.4548 19.8134L54.8946 16.8991H48.6356L47.6398 19.7926L42.6231 19.803C45.0114 13.8272 47.4048 7.85358 49.8033 1.8822C50.2116 0.86933 50.9361 0.35383 52.0041 0.359011C52.8207 0.366782 54.1527 0.366781 56.0025 0.3616L59.8814 19.8056L55.4548 19.8134ZM50.0448 12.9072H54.078L52.5718 5.6021L50.0448 12.9072ZM17.7068 0.356419L22.7508 0.3616L14.9532 19.816L9.84697 19.8108C8.56293 14.6721 7.29486 9.52917 6.04279 4.38199C5.79383 3.35617 5.30088 2.63861 4.35232 2.29926C2.95121 1.81077 1.54359 1.34271 0.129883 0.895235L0.129883 0.364191H8.18886C9.58306 0.364191 10.3972 1.06621 10.6586 2.5065C10.9225 3.94939 11.5847 7.62266 12.6503 13.5263L17.7068 0.356419ZM29.682 0.3616L25.6936 19.8108L20.8935 19.8056L24.877 0.356419L29.682 0.3616V0.3616ZM39.4165 0.00152588C40.853 0.00152588 42.663 0.467809 43.7037 0.895235L42.8621 4.92858C41.9211 4.53483 40.3725 4.00379 39.0704 4.02192C37.1783 4.0556 36.0082 4.88195 36.0082 5.67464C36.0082 6.96469 38.0397 7.61489 40.131 9.0241C42.5186 10.6302 42.8323 12.0731 42.8024 13.6403C42.77 16.8939 40.131 20.1035 34.5642 20.1035C32.0247 20.0646 31.1085 19.8419 29.0372 19.0777L29.9135 14.8682C32.0222 15.7878 32.916 16.0805 34.7185 16.0805C36.3692 16.0805 37.7858 15.3863 37.7982 14.1765C37.8082 13.3165 37.3003 12.8891 35.448 11.827C33.5957 10.7623 30.9965 9.28833 31.0314 6.33002C31.0737 2.54277 34.5218 0.00152588 39.419 0.00152588H39.4165Z" fill="white"/>
  </g>
  <defs>
  <clipPath id="clip0_892_18334">
  <rect width="59.7515" height="19.8947" fill="white" transform="translate(0.129883 0.105286)"/>
  </clipPath>
  </defs>
  </svg>
  `
});

export default script;