

import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'AdminIcon',
  props: ['color'],
  template: `
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.5 20.4291H18.6767C17.81 20.4291 16.9867 20.7649 16.38 21.3716L14.5275 23.2024C13.6825 24.0366 12.3067 24.0366 11.4617 23.2024L9.60916 21.3716C9.00249 20.7649 8.16833 20.4291 7.3125 20.4291H6.5C4.70167 20.4291 3.25 18.9882 3.25 17.2116V5.38156C3.25 3.6049 4.70167 2.16406 6.5 2.16406H19.5C21.2983 2.16406 22.75 3.6049 22.75 5.38156V17.2007C22.75 18.9774 21.2983 20.4291 19.5 20.4291Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.0759 9.69375C13.0326 9.69375 12.9675 9.69375 12.9134 9.69375C11.7759 9.65042 10.8767 8.72958 10.8767 7.58125C10.8767 6.41125 11.8192 5.46875 12.9892 5.46875C14.1592 5.46875 15.1017 6.42208 15.1017 7.58125C15.1126 8.72958 14.2134 9.66125 13.0759 9.69375Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.0208 12.9531C8.57998 13.9173 8.57998 15.4881 10.0208 16.4523C11.6566 17.5465 14.3433 17.5465 15.9791 16.4523C17.42 15.4881 17.42 13.9173 15.9791 12.9531C14.3433 11.8698 11.6675 11.8698 10.0208 12.9531Z" :stroke="color || '#22396D'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;